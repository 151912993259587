// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-agreements-gmbh-subscription-1-mdx": () => import("./../../../src/pages/agreements/gmbh/subscription1.mdx" /* webpackChunkName: "component---src-pages-agreements-gmbh-subscription-1-mdx" */),
  "component---src-pages-agreements-gmbh-support-services-1-mdx": () => import("./../../../src/pages/agreements/gmbh/support-services1.mdx" /* webpackChunkName: "component---src-pages-agreements-gmbh-support-services-1-mdx" */),
  "component---src-pages-agreements-inc-subscription-1-mdx": () => import("./../../../src/pages/agreements/inc/subscription1.mdx" /* webpackChunkName: "component---src-pages-agreements-inc-subscription-1-mdx" */),
  "component---src-pages-agreements-inc-support-services-1-mdx": () => import("./../../../src/pages/agreements/inc/support-services1.mdx" /* webpackChunkName: "component---src-pages-agreements-inc-support-services-1-mdx" */),
  "component---src-pages-carbon-bot-privacy-policy-mdx": () => import("./../../../src/pages/carbon-bot-privacy-policy.mdx" /* webpackChunkName: "component---src-pages-carbon-bot-privacy-policy-mdx" */),
  "component---src-pages-code-of-conduct-mdx": () => import("./../../../src/pages/code-of-conduct.mdx" /* webpackChunkName: "component---src-pages-code-of-conduct-mdx" */),
  "component---src-pages-contribute-mdx": () => import("./../../../src/pages/contribute.mdx" /* webpackChunkName: "component---src-pages-contribute-mdx" */),
  "component---src-pages-customers-404-jsx": () => import("./../../../src/pages/customers/404.jsx" /* webpackChunkName: "component---src-pages-customers-404-jsx" */),
  "component---src-pages-join-mdx": () => import("./../../../src/pages/join.mdx" /* webpackChunkName: "component---src-pages-join-mdx" */),
  "component---src-pages-product-welcome-mdx": () => import("./../../../src/pages/product/welcome.mdx" /* webpackChunkName: "component---src-pages-product-welcome-mdx" */),
  "component---src-pages-responsible-disclosure-policy-mdx": () => import("./../../../src/pages/responsible-disclosure-policy.mdx" /* webpackChunkName: "component---src-pages-responsible-disclosure-policy-mdx" */),
  "component---src-pages-showcase-404-jsx": () => import("./../../../src/pages/showcase/404.jsx" /* webpackChunkName: "component---src-pages-showcase-404-jsx" */),
  "component---src-pages-showcase-mdx": () => import("./../../../src/pages/showcase.mdx" /* webpackChunkName: "component---src-pages-showcase-mdx" */),
  "component---src-pages-summit-2019-mdx": () => import("./../../../src/pages/summit/2019.mdx" /* webpackChunkName: "component---src-pages-summit-2019-mdx" */),
  "component---src-pages-testing-scheming-jsx": () => import("./../../../src/pages/__testing/scheming.jsx" /* webpackChunkName: "component---src-pages-testing-scheming-jsx" */),
  "component---src-pages-thankyou-mdx": () => import("./../../../src/pages/thankyou.mdx" /* webpackChunkName: "component---src-pages-thankyou-mdx" */),
  "component---src-templates-case-study-detail-jsx": () => import("./../../../src/templates/CaseStudyDetail.jsx" /* webpackChunkName: "component---src-templates-case-study-detail-jsx" */),
  "component---src-templates-github-markdown-page-jsx": () => import("./../../../src/templates/GithubMarkdownPage.jsx" /* webpackChunkName: "component---src-templates-github-markdown-page-jsx" */),
  "component---src-templates-modular-page-jsx": () => import("./../../../src/templates/ModularPage.jsx" /* webpackChunkName: "component---src-templates-modular-page-jsx" */),
  "component---src-templates-showcase-detail-jsx": () => import("./../../../src/templates/ShowcaseDetail.jsx" /* webpackChunkName: "component---src-templates-showcase-detail-jsx" */)
}

